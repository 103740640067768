:root {
  --red: #c33527;
  --green: #4dae5b;
  --blue: #32a7d9;
  --yellow: #e6bd2f;
  --white: #fff;
  --lightest-grey: #f3f3f3;
  --lighter-grey: #a4a4a4;
  --light-grey: #9a9a9a;
  --dark-grey: #616161;
  --darker-grey: #313131;
  --darkest-grey: #212529;
  --disabled-bg: #ddd;
}

::-ms-reveal {
  display: none;
}

* {
  box-sizing: border-box;
}

.App {
  text-align: center;
}

html {
  height: 100%;
}

body {
  display: flex;
  flex-direction: column;
  height: 100%;
  margin: 0;
  font-size: 0.875em;
}

body #root {
  align-self: center;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.spinner {
  display: flex;
  margin: auto;
  margin-top: 50px;
  width: 32px;
  height: 32px;
  animation: spinner 0.5s infinite;
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }

  25% {
    transform: rotate(90deg);
  }

  50% {
    transform: rotate(180deg);
  }

  75% {
    transform: rotate(270deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

h1 {
  font-family: "Flexo-Bold", serif;
  margin: 5px 0;
  text-align: center;
  font-size: 1.5em;
}

p,
a {
  margin: 0;
}

.title-wrapper {
  margin-bottom: 30px;
}

.sub-title {
  margin-top: 5px;
}

.body-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: "Flexo-Regular", serif;
}

.body-wrapper {
  margin-left: 13px;
  margin-right: 13px;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 355px;
}

.footer-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 10%;
}

.copyright {
  font-size: 0.625em;
  color: #4e4e4e;
  font-family: "Flexo-Regular", serif;
  text-align: center;
  white-space: pre-line;
  width: 355px;
}
