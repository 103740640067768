body {
  margin: 0;
  font-family:
    -apple-system,
    BlinkMacSystemFont,
    'Segoe UI',
    'Roboto',
    'Oxygen',
    'Ubuntu',
    'Cantarell',
    'Fira Sans',
    'Droid Sans',
    'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family:
    source-code-pro,
    Menlo,
    Monaco,
    Consolas,
    'Courier New',
    monospace;
}

@font-face {
  font-family: 'Flexo-Regular';
  src: url('https://static.prod.pokemon.com/fonts/flexo-regular.eot');
  src: url('https://static.prod.pokemon.com/fonts/flexo-regular.eot?#iefix') format('embedded-opentype'), url('https://static.prod.pokemon.com/fonts/flexo-regular.woff') format('woff'), url('https://static.prod.pokemon.com/fonts/flexo-regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Flexo-Bold';
  src: url('https://static.prod.pokemon.com/fonts/flexo-bold.eot');
  src: url('https://static.prod.pokemon.com/fonts/flexo-bold.eot?#iefix') format('embedded-opentype'), url('https://static.prod.pokemon.com/fonts/flexo-bold.woff') format('woff'), url('https://static.prod.pokemon.com/fonts/flexo-bold.ttf') format('truetype');
}
