.bullet-box {
  text-align: left;
  border-width: 1px;
  border-style: solid;
  border-radius: 5px;
  border-color: var(--light-grey);
  display: flex;
  flex-direction: column;
  margin-top: 5px;
  padding-inline-start: 12px;
  padding-right: 12px;
  list-style: none;
  width: 100%;
}

.bullet-item {
  padding-top: 15px;
  padding-bottom: 15px;
  border-bottom: 1px solid var(--disabled-bg);
  list-style-position: inside;
  display: block;
  margin-left: 10px;
}

.bullet-item::before {
  content: "•";
  margin-right: 5px;
}

.disable-bullet::before {
  content: none;
  margin-right: 0;
}
